<template>
  <div>
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-md-5 col-12">
        <h4>Manage</h4>
        <VerticalPills :items="categories" item-key="id">
          <template #item="{ item }">
            <a href="#" class="nav-link" :class="{ active: selectedCategoryId === item.id }" @click.prevent="onSelectCategory(item)">
              <div class="mb-0"><i class="fa fa-fw mr-1 text-primary" :class="`${item.icon}`"></i> {{ item.title }}</div>
            </a>
          </template>
        </VerticalPills>
      </div>
      <div class="col">
        <SectionTitle :icon="selectedCategory.icon">{{ selectedCategory.title }}</SectionTitle>
        <div v-if="selectedCategoryId === 'general'" class="col-xl-6">
          <FormGroup
            id="name"
            v-model="form.name"
            :disabled="disabled"
            :error="error['name']"
            label="Name"
            type="text"
            description="Your own reference for this account/meter."
          />
          <FormGroup
            id="type"
            v-model="form.type"
            :disabled="disabled"
            :error="error['type']"
            label="Utility Type"
            description="The type of utility this account/meter is for."
            type="select"
            :options="utilityTypeOptions"
          />
          <FormGroup
            id="tags"
            v-model="form.tags"
            placeholder="Add a tag..."
            :disabled="disabled"
            :error="error['tags']"
            label="Tags"
            type="tag-manager"
            description="Categorise accounts and enable filtering in dynamic reports."
          />
          <FormGroup
            id="parentAccountId"
            v-model="form.parentAccountId"
            :disabled="disabled || accountOptions.length === 0"
            :error="error['parentAccountId']"
            label="Parent Account (For sub-meters)"
            :config="{ allowClear: true }"
            description="The parent account this sub-meter belongs to. Leave blank if this is an primary account/meter"
            type="select2"
            :options="accountOptions"
          />
          <FormGroup
            id="group"
            v-model="form.group"
            :disabled="disabled"
            :error="error['group']"
            description="An optional group name to group multiple sub-meters (e.g. Floor 1)"
            label="Group"
            type="text"
          />
        </div>
        <div v-else-if="selectedCategoryId === 'identification'" class="col-xl-6">
          <FormGroup
            v-if="form.type === 'electricity'"
            id="meterPointNumber"
            v-model="form.meterPointNumber"
            :disabled="disabled"
            :error="error['meterPointNumber']"
            label="MPAN"
            type="text"
          />
          <FormGroup
            v-if="form.type === 'gas'"
            id="meterPointNumber"
            v-model="form.meterPointNumber"
            :disabled="disabled"
            :error="error['meterPointNumber']"
            label="MPR"
            type="text"
          />
          <FormGroup
            v-if="form.type === 'water'"
            id="meterPointNumber"
            v-model="form.meterPointNumber"
            :disabled="disabled"
            :error="error['meterPointNumber']"
            label="Water SPID"
            type="text"
          />
          <FormGroup
            v-if="form.type === 'water'"
            id="meterPointNumber2"
            v-model="form.meterPointNumber2"
            :disabled="disabled"
            :error="error['meterPointNumber2']"
            label="Waste SPID"
            type="text"
          />

          <FormGroup
            id="meterSerialNumber"
            v-model="form.meterSerialNumber"
            :disabled="disabled"
            :error="error['meterSerialNumber']"
            label="Meter Serial Number"
            type="text"
          />
          <FormGroup
            id="siteCode"
            v-model="form.siteCode"
            :disabled="disabled"
            :error="error['siteCode']"
            description="The site code for this account"
            label="Site Code"
            type="text"
          />
          <FormGroup
            id="financialCode"
            v-model="form.financialCode"
            :disabled="disabled"
            :error="error['financialCode']"
            label="Financial Code"
            description="The financial code for this account"
            type="text"
          />
          <FormGroup
            id="propertyCode"
            v-model="form.propertyCode"
            :disabled="disabled"
            :error="error['propertyCode']"
            label="Property Code"
            description="Property code"
            type="text"
          />
          <FormGroup
            id="propertyScheduleCode"
            v-model="form.propertyScheduleCode"
            :disabled="disabled"
            :error="error['propertyScheduleCode']"
            label="Property Schedule Code"
            description="Property Schedule code"
            type="text"
          />
        </div>
        <div v-if="selectedCategoryId === 'status'" class="col-xl-6">
          <FormGroup
            v-if="isCreate"
            id="status"
            v-model="form.status"
            :disabled="disabled"
            :error="error['status']"
            label="Status"
            type="select"
            :options="[
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' }
            ]"
          />
          <div v-else class="mb-4 font-w600 font-size-h5">
            Status:
            <span class="text-capitalize" :class="{ 'text-success': value.status === 'active', 'text-warning': value.status === 'inactive' }">
              {{ value.status }}</span
            >
          </div>

          <table v-if="sortedStatusHistory.length > 0" class="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Value</th>
                <th>Notes</th>
                <th class="text-right"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in sortedStatusHistory" :key="item._id">
                <td>{{ item.date | date }}</td>
                <td>{{ item.value | capitalize }}</td>
                <td>{{ item.notes || '-' }}</td>
                <td class="text-right">
                  <button class="btn btn-link text-secondary mr-2" @click="() => onClickEditStatus(item)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button class="btn btn-link text-danger" @click="() => onClickDeleteStatus(item)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="alert alert-warning">No status history.</div>
          <button class="btn btn-primary" data-cy="add-status-btn" @click="onClickAddStatus">Add Status</button>
        </div>
        <div v-if="selectedCategoryId === 'supplier'" class="col-xl-6">
          <FormGroup
            id="supplierId"
            v-model="form.supplierId"
            :disabled="disabled"
            :error="error['supplierId']"
            :options="sortedSuppliers.map(s => ({ label: s.name, value: s._id }))"
            label="Supplier Name"
            type="select2"
          />
          <FormGroup
            id="supplierRef"
            v-model="form.supplierRef"
            :disabled="disabled"
            :error="error['supplierRef']"
            label="Supplier/Account Ref"
            type="text"
          />
          <FormGroup
            v-if="form.type === 'electricity'"
            id="esec"
            v-model="form.esec"
            :disabled="disabled"
            :error="error['esec']"
            label="Electricity Supply Emergency Code"
            :options="esecOptions"
            type="select"
          />
        </div>
        <div v-if="selectedCategoryId === 'solar'" class="col-xl-6">
          <FormGroup
            id="solarType"
            v-model="form.solarType"
            :disabled="disabled"
            :error="error['solarType']"
            label="Solar Type"
            type="select"
            description="The primary purpose of the solar PV system"
            :options="[
              { label: 'Generation & Export', value: 'both' },
              { label: 'Generation', value: 'generation' },
              { label: 'Export', value: 'export' }
            ]"
          />
        </div>
        <div v-if="selectedCategoryId === 'reporting'" class="col-xl-6">
          <FormGroup
            id="reportingType"
            v-model="form.reportingType"
            :disabled="disabled"
            :error="error['reportingType']"
            label="Reporting Type"
            type="select"
            :options="[
              { label: 'Default', value: null },
              { label: 'Like for like', value: 'likeForLike' },
              { label: 'Absolute', value: 'absolute' }
            ]"
          />
          <label>LOA Uploaded </label>
          <div class="text-gray-dark font-w600 mt-1 form-group">
            <span>
              <i v-if="form.gridfetchLoa" class="fa fa-lg fa-check-circle text-success"></i>
              <i v-else class="fa fa-lg fa-times-circle text-danger"></i
            ></span>

            <span v-if="form.gridfetchLoa"> Expiry Date: {{ form.gridfetchLoa.expiryDate | date }} </span>
          </div>
          <FormGroup
            id="isTrc"
            v-model="form.isTrc"
            :disabled="disabled"
            :error="error['isTrc']"
            label="Tenant Recharge Account"
            type="radio"
            :options="[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]"
            description="Displays a warning within the account validation area for invoices linked to this account"
          />

          <FormGroup
            id="financialCode"
            v-model="form.financialCode"
            :disabled="disabled"
            :error="error['financialCode']"
            label="Financial Code"
            description="The financial code for this account"
            type="text"
          />

          <FormGroup
            id="financialTransactionType"
            v-model="form.financialTransactionType"
            :disabled="disabled"
            :error="error['financialTransactionType']"
            label="Financial Transaction Type"
            description="The financial transaction type"
            type="text"
          />

          <FormGroup
            id="paymentSystem"
            v-model="form.paymentSystem"
            :disabled="disabled"
            :error="error['paymentSystem']"
            label="Payment System"
            description="Payment system for this account"
            type="text"
          />

          <FormGroup
            id="paymentTo"
            v-model="form.paymentTo"
            :disabled="disabled"
            :error="error['paymentTo']"
            label="Payment To"
            description="Where does payment go to?"
            type="text"
          />
        </div>
        <div v-if="selectedCategoryId === 'meter'" class="col-xl-6">
          <FormGroup
            id="meterUnits"
            v-model="form.meterUnits"
            :disabled="disabled"
            :error="error['meterUnits']"
            label="Meter Units"
            type="select"
            :options="unitOptions"
          />

          <FormGroup
            id="automaticMeterRead"
            v-model="form.automaticMeterRead"
            :disabled="disabled"
            :error="error['automaticMeterRead']"
            label="Automatic Meter Read"
            type="radio"
            :options="[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]"
          />

          <FormGroup
            id="tenant"
            v-model="form.meterUser"
            :disabled="disabled"
            :error="error['tenantLandlord']"
            label="Tenant/Landlord"
            type="select"
            :options="[
              { label: 'Tenant', value: 'tenant' },
              { label: 'Landlord', value: 'landlord' },
              { label: 'Void', value: 'void' }
            ]"
          />
          <FormGroup
            v-if="form.type !== 'waste'"
            id="dataCollector"
            v-model="form.dataCollector"
            :disabled="disabled"
            :error="error['dataCollector']"
            label="Data Collector"
          />
          <FormGroup
            v-if="form.type === 'electricity'"
            id="capacity"
            v-model="form.capacity"
            :disabled="disabled"
            :error="error['capacity']"
            label="Capacity"
            type="text"
          />
          <FormGroup
            id="factor"
            v-model="form.factor"
            :disabled="disabled"
            :error="error['factor']"
            label="Correction Factor"
            type="number"
            description="This will be applied to all consumption calculations. Gas correction factors are automatically applied and should not be set here. Please ensure units are correctly set before using this to avoid factors being applied twice."
          />
          <FormGroup
            id="registerIds"
            v-model="form.registerIds"
            placeholder="Add a register..."
            :disabled="disabled"
            description-position="top"
            :error="error['registerIds']"
            label="Additional Registers"
            type="select-array"
            description="As standard there is a single default register (1). Only add additional registers if this meter has more than one register. i.e. night (2)"
          />
        </div>
        <div v-if="selectedCategoryId === 'meterOperator'" class="col-xl-6">
          <FormGroup
            v-if="form.type !== 'waste'"
            id="meterOperator"
            v-model="form.meterOperator"
            :disabled="disabled"
            :error="error['meterOperator']"
            label="Meter Operator Name"
            type="text"
          />
          <FormGroup
            v-if="form.type !== 'waste'"
            id="meterOperatorType"
            v-model="form.meterOperatorType"
            :disabled="disabled"
            :error="error['meterOperatorType']"
            label="Meter Operator Type"
            type="select"
            :options="[
              { label: 'Lease', value: 'lease' },
              { label: 'Purchase', value: 'purchase' }
            ]"
          />
          <FormGroup
            v-if="form.type !== 'waste'"
            id="meterOperatorExpiryDate"
            v-model="form.meterOperatorExpiryDate"
            :disabled="disabled"
            :error="error['meterOperatorExpiryDate']"
            label="Meter Operator Expiry Date"
            type="datePicker"
          />
        </div>
        <div v-if="selectedCategoryId === 'building'" class="col-xl-6">
          <div class="form-group">
            <label for="floorArea">Floor Area</label>
            <div class="input-group">
              <FormItem id="floorArea" v-model="form.floorArea" label="Floor Area" type="text" class="input-group" />
              <div class="input-group-append">
                <FormItem
                  id="floorAreaUnit"
                  v-model="form.floorAreaUnit"
                  label="Floor Area Unit"
                  :error="error.floorAreaUnit"
                  :options="[
                    { value: 'metric', label: 'm2' },
                    { value: 'imperial', label: 'sq. ft' }
                  ]"
                  type="select"
                  :is-alt="true"
                />
              </div>
            </div>
            <FormError id="floorArea" :error="error.floorArea" />
          </div>
          <FormGroup
            id="meterLocation"
            v-model="form.meterLocation"
            :disabled="disabled"
            :error="error['meterLocation']"
            label="Location within building"
            type="textarea"
            rows="3"
          />
        </div>

        <div v-if="selectedCategoryId === 'integrations'" class="col-xl-6">
          <div v-if="!form.parentAccountId">
            <label>Supplier Portal</label>
            <a v-if="!form.portal" class="font-w600" href="#" @click.prevent="onClickOverridePortal(true)"
              ><div class="block block-rounded bg-light text-center d-flex align-items-center p-3">
                <i class="fa fa-triangle-exclamation text-warning fa-2x mr-3"></i
                ><span
                  >This account is currently using the supplier portal settings set for your entire company. Click here to override those settings for
                  this account.</span
                >
              </div></a
            >
            <div v-else>
              <a class="font-w600" href="#" @click.prevent="onClickOverridePortal(false)"
                ><div class="block block-rounded bg-light text-center d-flex align-items-center p-3">
                  <i class="fa fa-triangle-exclamation text-warning fa-2x mr-3"></i>
                  <span
                    >This account is using custom portal settings. Click here to use company-wide supplier settings for this account instead.</span
                  >
                </div></a
              >
              <PortalForm :data.sync="form.portal" :error="error" />
            </div>
          </div>
          <FormGroup
            id="deviceId"
            v-model="form.deviceId"
            :disabled="disabled"
            :error="error['deviceId']"
            label="Device ID"
            type="text"
            description="The primary identifier used by a third-party system"
          />

          <label>Third Party Identifiers</label>
          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Device ID</th>
                <th></th>
              </tr>
            </thead>
            <tr v-for="thirdParty in form.thirdParties" :key="thirdParty._id">
              <td>
                <strong>{{ thirdParty.name }}</strong>
              </td>
              <td>{{ thirdParty.deviceId }}</td>
              <td>
                <a class="text-danger font-w600" href="#" @click.prevent="() => onClickDeleteThirdParty(thirdParty)"
                  ><i class="fa fa-times fa-lg"></i
                ></a>
              </td>
            </tr>
          </table>

          <div class="p-3 rounded-md bg-light">
            <label>Add a new Third Party Identifier</label>
            <p class="font-size-sm font-w600 text-muted">
              Select the name of the third party and the ID used to identify this meter. This ID must be unique for the selected third party.
            </p>
            <div class="row align-items-center no-gutters mb-3">
              <div class="col-md-6 pr-2">
                <FormItem id="thirdPartyName" v-model="thirdPartyForm.name" type="select" :options="thirdPartyOptions" placeholder="Select..." />
              </div>
              <div class="col-md-4 pr-2">
                <FormItem id="thirdPartyDeviceId" v-model="thirdPartyForm.deviceId" placeholder="Device ID" />
              </div>
              <div class="col-md-2">
                <button class="btn btn-primary btn-sm" @click="onClickAddThirdParty">ADD</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ConfirmModal id="add-status" :open="!!modals.status" title="Change status" @close="modals.status = false" @submit="onConfirmStatusModal">
      <div v-if="modals.status">
        <FormGroup id="date" v-model="modals.status.date" label="Date" type="datePicker" />
        <FormGroup id="value" v-model="modals.status.value" label="Notes" type="select" :options="modals.status.options" />
        <FormGroup id="note" v-model="modals.status.notes" label="Notes" type="textarea" />
      </div>
    </ConfirmModal>

    <ConfirmModal :open="!!modals.deleteStatus" title="Delete status" @close="modals.deleteStatus = false" @submit="onConfirmDeleteStatusModal" />
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import Form from '@/components/forms/Form';
import FormError from '@/components/FormError';
import FormItem from '@/components/FormItem';
import FormGroup from '@/components/FormGroup';
import PortalForm from '@/components/PortalForm';
import SectionTitle from '@/components/base/SectionTitle';
import VerticalPills from '@/components/base/VerticalPills';

export default {
  name: 'AccountForm',
  components: {
    ConfirmModal,
    FormGroup,
    FormError,
    FormItem,
    PortalForm,
    SectionTitle,
    VerticalPills
  },
  extends: Form,
  props: {
    error: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    countryCode: {
      type: String,
      default: 'gb'
    },
    accountId: {
      type: String,
      required: true
    },
    isCreate: {
      type: Boolean,
      required: false,
      default: false
    },
    assetId: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      modals: {
        status: false,
        deleteStatus: false
      },
      unsaved: false,
      selectedCategoryId: 'general',
      thirdPartyForm: {
        id: null,
        deviceId: null
      }
    };
  },
  computed: {
    ...mapGetters({
      suppliers: 'supplier/suppliers',
      supplierLoadingAction: 'supplier/loadingAction',
      assetAccounts: 'asset/accounts',
      accounts: 'account/accounts',
      integrations: 'util/integrations',
      units: 'util/units',
      utilityTypes: 'util/utilityTypes'
    }),
    categories() {
      return [
        { id: 'general', title: 'General', icon: 'fa-gear' },
        { id: 'identification', title: 'Identification', icon: 'fa-tags' },
        ...(this.form.type === 'solar' ? [{ id: 'solar', title: 'Solar', icon: 'fa-solar-panel' }] : []),
        { id: 'meter', title: 'Meter Information', icon: 'fa-meter-bolt' },
        { id: 'meterOperator', title: 'Meter Operator', icon: 'fa-helmet-safety' },
        { id: 'status', title: 'Status History', icon: 'fa-timeline' },
        { id: 'supplier', title: 'Supplier', icon: 'fa-lightbulb' },
        { id: 'reporting', title: 'Financial & Reporting', icon: 'fa-file-lines' },
        { id: 'building', title: 'Building Information', icon: 'fa-building' },
        { id: 'integrations', title: 'Integrations', icon: 'fa-chart-network' }
      ];
    },
    selectedCategory() {
      return this.categories.find(c => c.id === this.selectedCategoryId);
    },
    accountOptions() {
      const filteredAccounts = this.accounts.filter(a => !a.parentAccountId);
      return [...filteredAccounts, { name: null, value: null }].map(a => ({ label: a.name, value: a._id }));
    },
    sortedSuppliers() {
      let suppliers = [...this.suppliers];

      suppliers.sort((a, b) => a?.name?.localeCompare(b.name));

      return suppliers;
    },
    sortedStatusHistory() {
      const sorted = [...(this.form.statusHistory || [])];

      sorted.sort((a, b) => new Date(b.date) - new Date(a.date));

      return sorted;
    },
    esecOptions() {
      return ['a', 'b', 'c', 'd', 'e', 'g', 'h', 'j', 'k', 'l', 'm', 'n', 'p', 'q', 'r', 's', 't', 'u'].map(s => ({
        label: s.toUpperCase(),
        value: s
      }));
    },
    thirdPartyOptions() {
      return [
        ...this.integrations.filter(({ category }) => category === 'company').map(({ name, service }) => ({ label: name, value: service })),
        { label: 'eSight Import Code', value: 'esightimportcode' },
        { label: 'eSight Meter Code', value: 'esightmetercode' },
        { label: 'eSight Meter ID', value: 'esightid' }
      ];
    },
    utilityTypeOptions() {
      return this.utilityTypes.map(i => ({ label: i.name, value: i.value }));
    },
    unitOptions() {
      return this.units.filter(u => u.types.includes(this.form.type)).map(u => ({ label: u.name, value: u.value }));
    }
  },
  async mounted() {
    if (this.$route.query?.category) this.selectedCategoryId = this.$route.query.category;

    // Load any data required for selects etc
    const promises = [
      this.listIntegrations(),
      this.listUtilityTypes(),
      this.listUnits(),
      this.listSuppliers({ data: { params: { type: 'supplier', countryCode: this.countryCode } } })
    ];

    if (this.value.assetId) {
      promises.push(this.getAccounts({ data: { params: { assetId: this.value.assetId } } }));
    }

    await Promise.all(promises);

    // Populate form with any existing information
    this.resetFormFromAccount(this.value);
  },
  methods: {
    ...mapActions({
      listSuppliers: 'supplier/list',
      getAccounts: 'account/list',
      addStatus: 'account/addStatusHistory',
      updateStatus: 'account/updateStatusHistory',
      deleteStatus: 'account/deleteStatusHistory',
      listIntegrations: 'util/listIntegrations',
      listUtilityTypes: 'util/listUtilityTypes',
      listUnits: 'util/listUnits'
    }),
    onSelectCategory(item) {
      if (this.selectedCategoryId !== item.id) {
        this.selectedCategoryId = item.id;

        this.$router.replace({ query: { category: item.id } });
      }
    },
    resetFormFromAccount(account) {
      this.form = {
        assetId: account.assetId,
        name: account.name,
        group: account.group,
        siteCode: account.siteCode,
        type: account.type,
        financialCode: account.financialCode,
        propertyCode: account.propertyCode,
        financialTransactionType: account.financialTransactionType,
        propertyScheduleCode: account.propertyScheduleCode,
        paymentSystem: account.paymentSystem,
        paymentTo: account.paymentTo,
        supplierId: account.supplierId,
        supplierRef: account.supplierRef,
        automaticMeterRead: account.automaticMeterRead,
        meterPointNumber: account.meterPointNumber,
        meterPointNumber2: account.meterPointNumber2,
        meterSerialNumber: account.meterSerialNumber,
        meterType: account.meterType,
        status: account.status,
        statusHistory: account.statusHistory || [],
        meterUser: account.meterUser,
        meterUnits: account.meterUnits,
        floorArea: account.floorArea,
        floorAreaUnit: account.floorAreaUnit ? account.floorAreaUnit : 'metric',
        reportingType: account.reportingType,
        solarType: account.solarType,
        isTrc: account.isTrc,
        deviceId: account.deviceId,
        factor: account.factor,
        registerIds: account.registerIds || [],
        capacity: account.capacity,
        meterLocation: account.meterLocation,
        meterOperator: account.meterOperator,
        meterOperatorType: account.meterOperatorType,
        meterOperatorExpiryDate: account.meterOperatorExpiryDate,
        dataCollector: account.dataCollector,
        portal: account.portal || null,
        customIntegrations: account.customIntegrations || {},
        esec: account.esec,
        parentAccountId: account.parentAccountId,
        gridfetchLoa: account.gridfetchLoa,
        thirdParties: account.thirdParties || [],
        tags: account.tags || []
      };

      const { customIntegrations = { account: [] } } = this.$auth.settings;

      (customIntegrations.account || []).forEach(integration => {
        this.form.customIntegrations[integration] = account.customIntegrations[integration];
      });

      switch (account.type) {
        case 'electricity':
          this.form.meterUnits = 'kwh';
          break;
        case 'water':
          this.form.meterUnits = 'm3';
          break;
      }

      // For gas accounts that existed prior to meterUnits, default to 'm3'
      if (!account.meterUnits && account.type === 'gas') {
        this.form.meterUnits = 'm3';
      }
    },
    onClickAddStatus() {
      const status = this.form.status === 'active' ? 'inactive' : 'active';
      this.modals.status = {
        date: moment.utc().startOf('day').toDate(),
        value: status,
        notes: '',
        mode: 'add',
        options: [{ label: status.charAt(0).toUpperCase() + status.slice(1), value: status }]
      };
    },
    onClickEditStatus(item) {
      this.modals.status = {
        ...item,
        date: item.date,
        mode: 'edit'
      };
    },
    async onConfirmStatusModal() {
      let updatedAccount;

      const tempId = Math.floor(Math.random() * 100000);

      if (this.isCreate) {
        if (this.modals.status.mode === 'edit') {
          const matchingItem = this.form.statusHistory.find(item => item.tempId);

          matchingItem.date = this.modals.status.date;
          matchingItem.value = this.modals.status.value;
          matchingItem.notes = this.modals.status.notes;
        } else {
          this.form.statusHistory.push({
            tempId,
            date: this.modals.status.date,
            value: this.modals.status.value,
            notes: this.modals.status.notes
          });

          this.modals.status = false;
        }

        return;
      }

      if (this.modals.status.mode === 'edit') {
        updatedAccount = await this.updateStatus({
          id: this.accountId,
          statusId: this.modals.status._id,
          data: {
            date: this.modals.status.date,
            value: this.modals.status.value,
            notes: this.modals.status.notes
          }
        });
      } else {
        updatedAccount = await this.addStatus({
          id: this.accountId,
          data: {
            date: this.modals.status.date,
            value: this.modals.status.value,
            notes: this.modals.status.notes
          }
        });
      }

      this.modals.status = false;
      this.resetFormFromAccount(updatedAccount);
    },
    onClickDeleteStatus(item) {
      this.modals.deleteStatus = item;
    },
    async onConfirmDeleteStatusModal() {
      if (this.isCreate) {
        this.form.statusHistory = this.form.statusHistory.filter(item => item.tempId !== this.modals.deleteStatus.tempId);

        this.modals.deleteStatus = false;

        return;
      }

      const updatedAccount = await this.deleteStatus({
        id: this.accountId,
        statusId: this.modals.deleteStatus._id
      });

      this.modals.deleteStatus = false;
      this.resetFormFromAccount(updatedAccount);
    },
    onClickOverridePortal(isOverride) {
      if (isOverride) {
        this.form.portal = {};
      } else {
        this.form.portal = null;
      }
    },
    onClickAddThirdParty() {
      this.form.thirdParties.push({
        name: this.thirdPartyForm.name,
        deviceId: this.thirdPartyForm.deviceId
      });

      this.thirdPartyForm = {
        name: null,
        deviceId: null
      };
    },
    onClickDeleteThirdParty(thirdParty) {
      this.form.thirdParties = this.form.thirdParties.filter(t => t !== thirdParty);
    }
  }
};
</script>
